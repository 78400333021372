import React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import Title from "../components/title";
import TextBox from "../components/textBox";

const OfferPage = () => {
  return (
    <Layout pageTitle="Infos zum Coaching">
      <StaticImage className="w-full" src="../images/infos.jpg" alt="contact" />
      <Title title="Infos zum Coaching" />
      <div className="p-6 flex flex-col space-y-16">
        <TextBox>
          <p>
            Ein Coaching dauert etwa 90 Minuten und kostet CHF 130.00. Das
            Coaching kann an der Moosbergstrasse 60 in Gelfingen stattfinden. Es
            ist auch möglich einen anderen Ort zu vereinbaren.
          </p>
          <StaticImage
            className="w-full"
            src="../images/room.jpg"
            alt="contact"
          />
        </TextBox>
      </div>
    </Layout>
  );
};

export default OfferPage;
