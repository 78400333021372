import React from "react";

const TextBox = ({ children }) => {
  return (
    <div className="max-w-3xl border-2 mx-auto rounded border-primary p-5 flex gap-12 flex-col">
      {children}
    </div>
  );
};

export default TextBox;
